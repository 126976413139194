html, body, #root {
  margin: 0;
  padding: 0;
}

/* Apply styles to the main layout container in App.js */
.layout-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* Adjust the flex container inside the Layout component */
.flex-container {
  display: flex;
  flex: 1; /* This ensures it expands to fill the available space */
}

.css-1wvake5 {
  height: 100vh;
}

.my-column {
  padding-left: 0;
  padding-right: 0;
}

.no-padding-container {
  padding-right: 0 !important;
  padding-left: 0 !important;
}