.metaFileUsersCard {
    border-radius: 10px; /* Adjust as needed */
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* This adds a light shadow */
    background-color:#242D42; /* Your specified RGB color */
    color: #F7F4F3; /* Makes text color #F7F4F3 */
    font-family: 'Chakra Petch', sans-serif;
  }
  
  .metaFileUsersCardBody {
    height: 300px; /* Set a fixed height */
    overflow-y: auto; /* Make content scrollable */
    -webkit-overflow-scrolling: touch;
    /* Hide scrollbar for Webkit browsers */
    &::WebkitScrollbar {
      display: none;
  }

  /* Hide scrollbar for IE, Edge, and Firefox */
  -ms-overflow-style: none;  /* for Internet Explorer, Edge */
  scrollbar-width: none;  /* for Firefox */
  }
  
  .metaFileUsersCard .table {
    width: 100%; /* Ensures the table uses full width */
    color: #F7F4F3; /* #F7F4F3 text color for table */
  }
  
  .metaFileUsersCard .table td, .metaFileUsersCard .table th {
    background-color:#242D42; /* Your specified RGB color */
    color: #F7F4F3; /* #F7F4F3 text color for table */
  }
  
  .metaFileUsersCard .table thead th {
    background-color:#242D42; /* Your specified RGB color */
    color: #F7F4F3; /* #F7F4F3 text color for table */
  }
  
  .metaFileUsersCard .table tbody td {
    background-color:#242D42; /* Your specified RGB color */
    color: #F7F4F3; /* #F7F4F3 text color for table */
  }

  .metaFileUsersCardContent {
    align-items: center; /* Aligns items vertically in the center */
    padding-bottom: 10px; /* Adds padding below the content for space */
    margin-bottom: 15px;
  }
  
  .metaFileUsersCard .card-img {
    width: 150px; /* Adjust size as you like */
    height: 150px; /* Same here */
    border-radius: 50%; /* For round image */
    margin-right: 10px; /* Space between image and text */
    margin-bottom: 10px; /* Adds space below the image */
  }
  
  .metaFileUsersCard .card-title {
    margin: 0; /* No extra margin */
    flex: 1; /* Allows title to take up remaining space */
    white-space: normal; /* Allows text to wrap */
    text-overflow: clip; /* Prevents text from being cut off */
    font-size: 1.55rem; /* Increase font size, adjust as needed */
    padding: 10px;
  }

  .scrollableContainer {
    padding: 100px; /* Adjust padding as needed */
    overflow-y: auto; /* Enables vertical scrolling */
    margin: auto;
    height: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Adds shadow for depth */
    background: #f5f5f5; /* Optional: Background color */
    border-radius: 10px; /* Optional: Rounds the corners */
  }

  .metafilecustomtable tr {
    border: none; /* Removes borders from all rows */
  }
  
  .metafilecustomtable tr:first-child td {
    border-top: 1px solid #dee2e6; /* Adds a top border to the first row */
  }
  
  .metafilecustomtable tr:last-child td {
    border-bottom: 1px solid #dee2e6; /* Adds a bottom border to the last row */
  }
  
  .metafilecustomtable td {
    border: none; /* Removes borders from individual cells */
  }
  
  .metafilecustomtable td:last-child {
    width: 60%; /* Adjust this value as needed */
    text-align: right; /* Right-aligns the content of the last cell */
  }

  .metafilecustomtable td:first-child {
    width: 40%; /* Adjust this value as needed */
    text-align: left; /* Left-aligns the content of the first cell */
  }
  
  .carousel-arrow-left, .carousel-arrow-right {
    font-size: 24px; /* Adjust the size as needed */
    cursor: pointer;
    color:#dee2e6; /* Adjust the color as needed */
    position: absolute;
    bottom: 10px; /* Distance from the bottom of the card */
}

.carousel-arrow-left {
    left: 10px; /* Adjust the position from the left edge */
}

.carousel-arrow-right {
    right: 10px; /* Adjust the position from the right edge */
}

.pagination {
  display: flex;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Align items vertically */
  color: black;
  padding: 10px; /* Adjust padding as needed */
}

.pagination span {
  margin: 0 15px; /* Space around the page number */
}

.carousel-controls {
  display: flex;
  justify-content: space-between; /* Space out the left and right controls */
  padding: 10px; /* Add some padding */
  align-items: center;
}

.slider-container {
  position: static !important;
}

/* General Styles - Keep these as they are, they're fine for both desktop and mobile */

/* Mobile-specific Styles */
@media (max-width: 768px) { /* Adjust this breakpoint as needed */
  .metaFileUsersCard {
    padding: 10px; /* Smaller padding for mobile */
    font-size: 0.8em; /* Smaller font size */
  }

  .metaFileUsersCardBody {
    height: auto; /* Dynamic height for different contents */
  }

  .metaFileUsersCardContent, .metaFileUsersCard .card-img, .metaFileUsersCard .card-title {
    width: auto; /* Full width on mobile */
    height: auto; /* Auto height for responsive design */
    margin-bottom: 5px; /* Less space between elements */
  }

  .metaFileUsersCard .card-img {
    width: 100px; /* Smaller image size */
    height: 100px;
  }

  .metaFileUsersCard .card-title {
    font-size: 1.2rem; /* Smaller font size */
  }

  .scrollableContainer {
    padding: 20px; /* Less padding */
  }

  .metafilecustomtable td:last-child, .metafilecustomtable td:first-child {
    width: auto; /* Full width for table cells */
    text-align: left; /* Uniform text alignment */
  }

  .carousel-arrow-left, .carousel-arrow-right {
    font-size: 18px; /* Smaller arrow size for carousel */
  }

  .pagination {
    padding: 5px; /* Less padding */
  }

  /* Additional styles for better touch interaction */
  button, .carousel-arrow-left, .carousel-arrow-right {
    min-width: 44px; /* Minimum touch target size */
    min-height: 44px;
    padding: 10px; /* Larger padding for easier touch */
  }

  .carousel-controls {
    position: absolute;
    width: 100%;
    bottom: 10px; /* Position at the bottom of the card */
    left: 0;
  }
}
