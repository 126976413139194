.gradient-pending {
    background-color: #FAF4C0; /* Light pastel yellow */
    border: 1px solid black; /* Light border */
}

.gradient-rejected {
    background-color: #FFC2A1; /* Light pastel red */
    border: 1px solid black;
}

.gradient-approved {
    background-color: #A3C4A8; /* Light pastel green */
    border: 1px solid black;
}

.custom-dropdown .btn {
    background-color: #383631;
    border-color: #383631;
}

.card {
    box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.3); /* Softer shadow */
    border-radius: 10px; /* Rounded corners */
    min-height: 575px;
}

/* Additional styling for text and buttons inside the card, if needed */
.card-body h4 {
    color: white; /* Softer text color */
}

.card-body .btn {
    border: none;
    border-radius: 5px;
}

.card-body {
    padding: 20px; /* Adjust as needed */
}

.info-rows, .approval-buttons, .pdf-component {
    margin: 10px 0; /* Even spacing between elements */
}

/* Additional adjustments as needed for specific elements */
.info-row, .approval-buttons, .pdf-component {
    text-align: center;
}

.info-row {
    display: flex;
    justify-content: center; /* Center the label and value group */
    padding: 10px 0;
    width: 100%;
}

.info-row-label, .info-row-value {
    text-align: center; /* Align text to left within each column */
    flex-basis: 50%; /* Each takes up 50% of the .info-row width */

}

.button-approve {
    background-color: #5ee6b6; /* Pastel green for approve button */
    border: none;
    color: black; /* Choose a color for the text that ensures readability */
}

.button-reject {
    background-color: #ea6f92; /* Pastel red for reject button */
    border: none;
    color: black;
}

/* Add hover effects if needed */
.button-approve:hover, .button-reject:hover {
    opacity: 0.8;
}

.approval-buttons {

    display: flex;          /* Enable Flexbox */
    justify-content: center;  /* Center items horizontally */
    align-items: center;

}

.pdf-component{

    display: flex;          /* Enable Flexbox */
    justify-content: center;  /* Center items horizontally */
    align-items: center;
}

.info-rows {
    margin-left: 30px;
    white-space: normal;       /* Allow text to wrap naturally */
    overflow-wrap: break-word; /* Enable word wrapping for long words */
    word-wrap: break-word;     /* Alternative for older browsers */
    overflow: hidden;          /* Prevent text from overflowing the div */
    text-overflow: ellipsis;   /* Optional: Indicate text overflow with ellipsis */
  }
  
  
  .info-row-label {
    text-align: right; /* Right-align the key (label) */
  }
  
  .info-row-value {
    text-align: left; /* Left-align the value */
  }
  